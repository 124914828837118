<template>
  <div class="box" v-if="showDetail">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="details.name"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox">
      <GSteps :List="steps" v-if="this.$route.query.appStatus == 1" />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="详细信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="供应商编码" :value="details.supplierId" />
                    <van-cell
                      title="供应商类型"
                      :value="details.supplierTypeName"
                    />
                    <van-cell title="启用说明" :value="details.enableReason" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item
                  title="产品目录"
                  name="2"
                  icon="label"
                  v-if="details.productDetailInfo"
                >
                  <!-- <van-cell title="产品目录" value="过期证书" /> -->
                  <van-cell title="产品目录" style="text-align: center"
                    ><p style="text-align: center">过期证书</p></van-cell
                  >
                  <van-cell
                    v-for="(item, index) in details.productDetailInfo"
                    :key="index"
                    :title="item.productName"
                    :value="
                      item.expireCertificate == 'undefined'
                        ? '无'
                        : item.expireCertificate
                    "
                  />
                </van-collapse-item>
                <van-collapse-item title="相关文件" name="4" icon="label">
                  <!-- <van-cell title="" 
                    ><div v-for="item in details.suppfiles" :key="item.fileId">
                      {{ item.title }}
                    </div></van-cell
                  > -->
                  <van-cell
                    title=""
                    v-if="details.suppfiles && details.suppfiles.length > 0"
                    ><div
                      class="preview"
                      v-for="item in details.suppfiles"
                      :key="item.id"
                    >
                      <a :href="item.href">{{ item.title }}</a>
                    </div></van-cell
                  >
                  <van-cell title="" v-else>暂无文件</van-cell>
                </van-collapse-item>
                <van-collapse-item title="申请人信息" name="5" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="姓名" :value="details.createPerson" />
                    <van-cell title="单位" :value="details.nameAbbr" />
                    <van-cell
                      title="联系方式"
                      :value="details.createPersonTel"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <!-- 审核历史 -->
          <van-tab title="审批记录" name="b" v-if="isPass">
            <Task :applyId="stockaffirmId" :tableName="1" :applyType="1" />
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="footer" v-if="!isPass">
      <div class="footer-left" @click="agree">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
  </div>
  <div class="box" v-else>
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="details.name"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <Noagree :onSubmit="auditApply" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import noagree from "../noagree.vue";
export default {
  components: { noagree },
  name: "gsnSupplierApplyDetails",
  data() {
    return {
      examineFlag: 1,
      showDetail: true,
      supplierId: "",
      supplierType: "",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      steps: [],
      workflowId: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "name",
            label: "供应商名称",
          },
          {
            prop: "supplierTypeName",
            label: "供应商类型",
          },
          {
            prop: "supplierName",
            label: "专业分类",
          },
          {
            prop: "supplierAttributeName",
            label: "供应商属性",
          },
        ],
      },
    };
  },
  async created() {
    await this.getDetail();
    if (this.$route.query.appStatus != 2) {
      await this.getTask();
    }
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    isPass() {
      return this.$route.query.appStatus != 1;
    },
  },
  methods: {
    // onPreview(item) {
    //   let query = {
    //     id: item.fileId,
    //     type: item.file.fileType
    //   };
    //   this.$router.push({ name: "previewGsn", query })
    // },
    formatDate(date) {
      let d = new Date(date);
      const month =
        d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      const day = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
      return `${d.getFullYear()}-${month}-${day}`;
    },
    async getDetail() {
      const params = {
        applyId: this.stockaffirmId,
        supplierId: this.$route.query.statusId,
        supplierType: "undefined",
      };
      let data = await this.$api.gsn.getCertificateExpiredEnableDetail(params);
      this.workflowId = data.workflowId;
      this.supplierId = data.supplierId;
      this.supplierType = data.supplierType;
      console.log(data);
      if (data.files && data.files.length > 0) {
        data.files.forEach((item) => {
          let api = "/api-gsn/FileAction!downloadByApp/";
          // let title = item.title.substring(item.title.lastIndexOf(".") + 1);
          // if (item.fileType == 1) {
          //   api = "/api-gsn/FileAction!showImageByApp/";
          // }
          item.href = `${api}${item.id}-${item.title}`;
          console.log(item.href);
        });
      }
      const details = {
        name: data.supplierName,
        supplierId: data.supplierId,
        supplierTypeName: this.$route.query.name,
        enableReason: data.enableReason,
        productDetailInfo: data.productDetailInfo || [],
        suppfiles: data.files,
        nameAbbr: data.enterprise.name,
        createPerson: data.businessApply.createPerson,
        createPersonTel: data.businessApply.createPersonTel,
      };
      this.details = details;
    },
    async getTask() {
      const params = {
        workflowId: this.workflowId,
      };
      let data = await this.$api.gsn.getTaskList(params);
      this.steps = data;
    },
    onClickLeft() {
      if (this.showDetail) {
        this.$router.go(-1);
      } else {
        this.showDetail = true;
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {},
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.auditApply(1);
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      // this.$router.push({ name: "noagree" });
      this.showDetail = false;
    },
    async auditApply(flag, auditOpinion = "审核同意") {
      const params = {
        applyId: this.stockaffirmId,
        supplierId: this.supplierId,
        supplierType: this.supplierType,
        flag: flag,
        opinion: auditOpinion,
        workflowId: this.workflowId,
      };
      let data = await this.$api.gsn.auditCertificateExpiredEnable(params);
      console.log(data);
      Toast.success("提交成功");
      let query = {
        audit: 1,
      };
      this.$router.push({ name: "gsnExpiredCertificate", query });
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
.texts {
  padding: 10px 20px;
  text-indent: 2em;
  line-height: 24px;
}
.preview {
  color: blue;
}
</style>
